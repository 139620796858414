
<template>
  <div class="page">
    <!-- Your page content -->
    <div class="image-container">
      <img class="image" :src="picture3" alt="Undangan Setrum">
      
    </div>
    <div @click="openMap" class="overlay">BUKA APLIKASI PETA</div>
  </div>
</template>

<script>
import picture3 from '@/assets/Picture3.png';

export default {
  name: "Page3",
  data() {
    return {
      picture3: picture3
    };
  },
  methods: {
    openMap() {
      // Coordinates for the location const latitude = -6.713588672053829; const longitude = 107.02260859400144;
      
      const latitude = -6.8051217152263215;
      const longitude = 107.15350442779895;
      // Detect the user's OS
      const os = this.detectOS();
      let url;

      if (os === 'iOS') {
        // Construct the URL for Apple Maps
        url = `http://maps.apple.com/?q=${latitude},${longitude}`;
      } else {
        // Default to Google Maps for other OS
        url = `https://www.google.com/maps/?q=${latitude},${longitude}`;
      }

      // Open the URL in a new tab/window
      window.open(url, '_blank');
    },
    detectOS() {
      /*
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
      const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

      if (macosPlatforms.indexOf(platform) !== -1) {
        return 'macOS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        return 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'Windows';
      } else if (/Android/.test(userAgent)) {
        return 'Android';
      } else if (!os && /Linux/.test(platform)) {
        return 'Linux';
      }

      return 'unknown';*/
      const userAgentData = window.navigator.userAgentData;
      const userAgent = window.navigator.userAgent;
      let os = 'unknown';

      const macosPlatforms = ['Mac OS'];
      const windowsPlatforms = ['Windows'];
      const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      const linuxPlatforms = ['Linux'];

      // Check if userAgentData is available (Modern Browsers)
      if (userAgentData && userAgentData.platform) {
        const platform = userAgentData.platform;

        if (macosPlatforms.includes(platform)) {
          os = 'macOS';
        } else if (iosPlatforms.includes(platform)) {
          os = 'iOS';
        } else if (windowsPlatforms.includes(platform)) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (linuxPlatforms.includes(platform)) {
          os = 'Linux';
        }
      } else {
        // Fallback to userAgent for older browsers
        if (/Mac/.test(userAgent)) {
          os = 'macOS';
        } else if (/iPhone|iPad|iPod/.test(userAgent)) {
          os = 'iOS';
        } else if (/Windows/.test(userAgent)) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (/Linux/.test(userAgent)) {
          os = 'Linux';
        }
      }

      return os;
    }
    
  }

};
</script>

<style scoped>

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  /*font-family: 'Roboto', sans-serif; /* Beautiful font */
  padding: 0px; /* Add some padding for better spacing */
  margin:0px 0px 0px 0px !important;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the container's height to 100% of the viewport height */
  padding: 0px; /* Add some padding for better spacing */
  margin:0px 0px 0px 0px !important;
}

.image {
  height: 80%; /* Make the image fill its container vertically */
  max-width: 100%; /* Ensure the image does not exceed its natural size */
  width: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the image fits within the container while preserving aspect ratio */
}


.overlay {
  position: absolute;
  /*width: 200px; /* Set the width of the overlay */
  height: 100px; /* Set the height of the overlay */
  top: 65%;
  bottom: 0;
  left: 0;
  right: 0;
  background: goldenrod; /*rgba(0, 0, 0, 0.5); /* Black background with opacity */
  color: black; 
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; /* Make the overlay invisible initially */
  transition: opacity 0.5s ease;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  cursor: pointer;
  /*font-family: 'Roboto', sans-serif; */
  font-size: 14pt;
  padding: 10px;
  max-width: 250px;
}

.overlay:hover {
  background-color: rgb(247, 205, 99);
}
</style>