<template>
  <div class="page">
    <!-- Your page content -->
    <div class="image-container">
      <img class="image" :src="picture5" alt="Undangan Setrum">
    </div>
    <!-- Install button -->
    <div class="overlay" v-if="deferredPrompt" @click="installApp">INSTALL APP</div>

  </div>
</template>

<script>
import picture5 from '@/assets/Picture5.png';

export default {
  name: "Page5",
  data() {
    return {
      picture5: picture5,
      deferredPrompt: null
    };
  },
  props: ['userid'], //dynamicURL
  mounted() {

    // Store the dynamic path '/userid' to prevent enduser using other userid
    localStorage.setItem("dynamicPath", "/"+this.userid);

    // Listen for the PWA installation promp from BROWSER
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Save the event for triggering later
      this.deferredPrompt = e;
    });

    // Add listener for the 'appinstalled' event to store dynamicPath
    window.addEventListener("appinstalled", () => {
      console.log("PWA was installed");

      // Store the dynamic path '/bujukan' in local storage when the app is installed
      localStorage.setItem("dynamicPath", "/"+this.userid);
    });
  },
  methods: {
    installApp() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
            // Store the dynamic path '/bujukan' in local storage when this page is loaded
          } else {
            console.log('User dismissed the install prompt');
          }
          this.deferredPrompt = null;
        });
      }
    }
  }
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column; /* Arrange content vertically */
  justify-content: center;
  align-items: center;
  height: 100vh;
  /*font-family: 'Roboto', sans-serif; /* Beautiful font */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%; /* Adjust the height of the image container */
  padding: 0; /* No extra padding */
  margin: 0; /* No extra margin */
}

.image {
  height: 100%; /* Make the image fill its container */
  max-width: 100%; /* Ensure the image does not exceed its natural size */
  object-fit: contain; /* Ensure the image fits within the container while preserving aspect ratio */
}

.install {
  margin-top: 20px; /* Add some space between the image and the button */
}


.overlay {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;


  /*width: 200px; /* Set the width of the overlay */
  height: 100px; /* Set the height of the overlay */
  top: 75%;
  bottom: 0;
  left: 0;
  right: 0;
  background: goldenrod; /*rgba(0, 0, 0, 0.5); /* Black background with opacity */
  color: black; 
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; /* Make the overlay invisible initially */
  transition: opacity 0.5s ease;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  cursor: pointer;
  /*font-family: 'Roboto', sans-serif; */
  font-size: 14pt;
  padding: 10px;
  max-width: 250px;
}

.overlay:hover {
  background-color: rgb(247, 205, 99);
}

</style>
