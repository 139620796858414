<template>
  <div class="page">
    <!-- Your page content -->
    <div class="image-container">
      <img class="image" :src="picture4" alt="Undangan Setrum">
    </div>

    <!-- Overlay with QR Code -->
    <div :class="['overlay', { 'overlay-fullscreen': isFullscreen }]" @click="toggleFullscreen">
      <qrcode-vue
        :value="userid"
        :size="isFullscreen ? 350 : 230"
        level="H"
        class="qrcode"
      ></qrcode-vue>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import picture4 from '@/assets/Picture4.png';

export default {
  name: "Page4",
  data() {
    return {
      picture4: picture4,
      isFullscreen: false // State to toggle between fullscreen and normal view
    };
  },
  components: {
    QrcodeVue
  },
  props: ['userid'],
  methods: {
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen; // Toggle between fullscreen and normal view
    }
  }
};
</script>

<style scoped>
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 0px;
  margin: 0px !important;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0px;
  margin: 0px !important;
}

.image {
  height: 80%;
  max-width: 100%;
  width: auto;
  object-fit: contain;
}

/* Base overlay styling */
.overlay {
  position:absolute; /* Ensure the overlay stays fixed to the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 250px;
  top: 40%;
  background: white;
  color: black;
  transition: all 0.5s ease;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  cursor: pointer; 
}

/* Fullscreen overlay styling */
.overlay-fullscreen {
  position: absolute; /* Fixed positioning to force it to the most front */
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: none; /* Remove centering offset */
  /*z-index: 10000; /* Force this to the very top of the stacking order */
  border-radius: 0; /* Remove border radius when fullscreen */
}

/* QR code in fullscreen stays centered and large */
.qrcode {
  transition: all 0.5s ease; /* Smooth transition for size change */
}

/* Ensure QR code stays in the most front 
.overlay-fullscreen .qrcode {
  z-index: 1000; /* Highest z-index for the QR code 
}
*/


</style>
