
<template>
  <div class="page">
    <!-- Your page content -->
    <div class="image-container">
      <img class="image" :src="picture2" alt="Undangan Setrum">
    </div>
    <div @click="downloadICS" class="overlay">TAMBAHKAN PADA KALENDER</div>
  </div>
</template>

<script>
import picture2 from '@/assets/Picture2.png';
import { saveAs } from 'file-saver';

export default {
  name: "Page",
  data() {
    return {
      picture2: picture2
    };
  },
  methods: {
    downloadICS() {
      const event = this.createCalendarEvent();
      const blob = new Blob([event], { type: 'text/calendar' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'anita-fauzan-nikah.ics');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    createCalendarEvent() {
      // Example event data, you would replace this with your actual event details
      const start = '2024-09-15T11:00:00+07:00'; // March 3, 2024, 11:00:00 in WIB
      const end = '2024-09-15T14:00:00+07:00'; // March 3, 2024, 14:00:00 in WIB
      const summary = 'Pernikahan Anita-Fauzan';
      const location = 'Raider Gadung';
      const description = 'Celebrating the wedding of Anita and Fauzan';
      // Construct the ICS content
      return [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `DTSTART:${start}`,
        `DTEND:${end}`,
        `SUMMARY:${summary}`,
        `DESCRIPTION:${description}`,
        `LOCATION:${location}`,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');
    },
  }
}
</script>

<style scoped>

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  /*font-family: 'Roboto', sans-serif; /* Beautiful font */
  padding: 0px; /* Add some padding for better spacing */
  margin:0px 0px 0px 0px !important;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set the container's height to 100% of the viewport height */
  padding: 0px; /* Add some padding for better spacing */
  margin:0px 0px 0px 0px !important;
}

.image {
  height: 80%; /* Make the image fill its container vertically */
  max-width: 100%; /* Ensure the image does not exceed its natural size */
  width: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure the image fits within the container while preserving aspect ratio */
}

.overlay {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;


  /*width: 200px; /* Set the width of the overlay */
  height: 100px; /* Set the height of the overlay */
  top: 65%;
  bottom: 0;
  left: 0;
  right: 0;
  background: goldenrod; /*rgba(0, 0, 0, 0.5); /* Black background with opacity */
  color: black; 
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; /* Make the overlay invisible initially */
  transition: opacity 0.5s ease;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  cursor: pointer;
  /*font-family: 'Roboto', sans-serif; */
  font-size: 14pt;
  padding: 10px;
  max-width: 250px;
  
}

.overlay:hover {
  background-color: rgb(247, 205, 99);
}
</style>