<template>
  <div class="background-container">
    <swiper :slidesPerView="1" :pagination="{ clickable: true }" navigation>
      <swiper-slide><Page1 :userid="userid"/></swiper-slide>
      <swiper-slide><Page2 /></swiper-slide>
      <swiper-slide><Page3 /></swiper-slide>
      <swiper-slide><Page4 :userid="userid"/></swiper-slide>
      <swiper-slide><Page5 :userid="userid"/></swiper-slide>
    </swiper>

    <!-- Dark overlay -->
    <div v-if="showGuidance" class="dark-overlay"></div>

    <!-- Swipe to start guidance message -->
    <div v-if="showGuidance" class="swipe-to-start">
      <p>
        <span class="hand-icon">
          <img src="@/assets/hand.png" />
        </span> 
        <p>GESER KANAN UNTUK MEMULAI</p>
      </p>
    </div>
  </div>
</template>

<script>
import Page1 from '../components/Page1.vue';
import Page2 from '../components/Page2.vue';
import Page3 from '../components/Page3.vue';
import Page4 from '../components/Page4.vue';
import Page5 from '../components/Page5.vue';

export default {
  name: 'HomeView',
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5
  },
  props: ['userid'],

  data() {
    return {
      showGuidance: false, // Control visibility of the guidance message
    };
  },
  mounted() {
    // Add an event listener for when all assets are loaded
    window.addEventListener('load', this.handlePageLoad);
  },
  methods: {
    handlePageLoad() {
      // Retrieve the number of visits from localStorage
      const visits = localStorage.getItem('visitCount') || 0;

      if (visits < 3) {
        this.showGuidance = true;
        // Increment the visit count and store it back in localStorage
        localStorage.setItem('visitCount', parseInt(visits) + 1);

        // Hide the guidance message after 2.5 seconds
        setTimeout(() => {
          this.showGuidance = false;
        }, 2500); // 2500ms = 2.5 seconds
      }
    }
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener('load', this.handlePageLoad);
  }
};
</script>

<style scoped>
.background-container {
  /* Full screen size */
  width: 100vw;
  height: 100vh;

  /* Set the background image */
  background-image: url('@/assets/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Make the image cover the entire screen */
  background-color: #032977;
}

/* Swipe to start guidance styling */
.swipe-to-start {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 20px;
  color: #000000;
  background-color: goldenrod; 
  padding: 30px;
  border-radius: 8px;
  animation: fadeout 2.5s forwards; /* Optional fade-out effect */
}

/* Hand icon animation */
.hand-icon {
  display: inline-block;
  margin-right: 10px;
  animation: swipe 2.5s infinite ease-in-out; /* Hand swipe animation */
}

.hand-icon img {
  width: 90px; /* Set width */
  height: 90px; /* Set height */
}

/* Swipe hand animation */
@keyframes swipe {
  0% {
    transform: translateX(40px);
  }
  50% {
    transform: translateX(-40px); /* Move right */
  }
  100% {
    transform: translateX(40px); /* Move back to the original position */
  }
}

/* Fade out animation */
@keyframes fadeout {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Dark overlay styling */
.dark-overlay {
  position: fixed;
  top: 0;
  left: 50%; /* Center horizontally */
  width: 100vw; /* Set the width to 80% of the screen */
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent dark overlay */
  transform: translateX(-50%); /* Shift by half the width to perfectly center */
  z-index: 1; /* Make sure it's behind the guidance message */
}
</style>
