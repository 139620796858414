import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/UndanganView.vue'
import PindaiView from '../views/PindaiView.vue'

const routes = [
  {
    path: '/',
    redirect: '/',
    name: 'HomeView',
    component: HomeView,
    props:true
  },
  {
    path: '/:userid',
    name: 'HomeView',
    component: HomeView,
    props: true
  },
  {
    path: '/pindai',
    name: 'PindaiView',
    component: PindaiView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
