<template>

  <!-- Preloader -->
  <div v-if="isLoading" class="preloader">
    <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
  </div>
      
  <router-view/>
</template>

<script>

export default {
  data() {
    return {
      isLoading: true, // Initially show the preloader
    };
  }, 
  
  mounted() {

    // Listen for window load event to detect when all assets are fully loaded
    window.addEventListener('load', this.handleLoad);

    // Retrieve the dynamic path from local storage
    const dynamicPath = localStorage.getItem("dynamicPath");

    // If a dynamic path is stored, navigate to that path
    if (dynamicPath) {
      this.$router.push(dynamicPath);
    }
  },

  methods: {
    handleLoad() {
      // Hide the preloader after all assets are loaded
      this.isLoading = false;
    },
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener('load', this.handleLoad);
  },

};
</script>

<style scoped>
/* Style the preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #032977;
  font-size: 24px;
  font-weight: bold;
  z-index: 9999;
  color: white;
  font-size:xx-large;
}

/* Style for the animated dots */
.dot {
  opacity: 0;
  animation: dot-blink 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s; /* Delay for the second dot */
}

.dot:nth-child(3) {
  animation-delay: 1s; /* Delay for the third dot */
}

@keyframes dot-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Hide the main content initially */
.main-content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

/* Show the main content after loading */
.main-content.loaded {
  opacity: 1;
}
</style>